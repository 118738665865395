import { SPARTA_API } from "utils/axios";
import { getRefreshToken, getToken } from "utils/token";

import { trackIdentifyUser } from "./segment";
import { AuthenticateResponse, SessionRefreshResponse, UserMe } from "./types";

export const officeLogin = ({ email, password }: Record<"email" | "password", string>) =>
  SPARTA_API().post<AuthenticateResponse>("/auth/v2/authenticate", {
    email,
    password,
    platform: "excel",
    platformVersion: "1.0.0",
  });

export const refresh = async () => {
  const tokens = { accessToken: getToken(), refreshToken: getRefreshToken() };

  const request = {
    ...tokens,
    platform: "excel",
    platformVersion: "1.0.0",
  };

  const response = await SPARTA_API().post<SessionRefreshResponse>("/auth/v2/refresh", request);

  return response.data;
};

export const acceptTerms = () => SPARTA_API().put("/excel/user/terms-and-conditions");

export const me = async (token?: string) => {
  const { data } = await SPARTA_API({ token }).get<UserMe>("/users/me");

  if (data) {
    if (location.pathname !== "/") {
      trackIdentifyUser(data.id, data.username, data.email);
    }
  }

  return data;
};
