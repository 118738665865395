import { Provider } from "services/liveCurves/types";

export const PERIOD_CODES = [
  "BOM",
  "M01",
  "M02",
  "M03",
  "M04",
  "M05",
  "M06",
  "M07",
  "M08",
  "M09",
  "M10",
  "M11",
  "M12",
  "M13",
  "M14",
  "M15",
  "M16",
  "BOQ",
  "Q01",
  "Q02",
  "Q03",
  "Q04",
  "BOY",
  "CL1",
  "CL2",
];

const TOTAL_ROWS = PERIOD_CODES.length;

export const TOTAL_ROWS_WITH_PRODUCT_HEADER = TOTAL_ROWS + 2;

// IMPORTANT: The order matters!
export const DATA_PROVIDERS = [
  {
    code: "DP_1",
    color: {
      fill: "#5F7EED",
      font: "#FFFFFF",
    },
  },
  {
    code: "DP_2",
    color: {
      fill: "#E62E2D",
      font: "#FFFFFF",
    },
  },
  {
    code: null,
    color: {
      fill: "#3E3E4D",
      font: "#FFFFFF",
    },
  },
] satisfies { code: Provider; [key: string]: any }[];

export const DATA_PROVIDER_PARAM_KEY = "DATA_PROVIDER";

export const FORMULA_REFER_ITSELF = "FORMULATEXT(INDIRECT(ADDRESS(ROW(),COLUMN())))";
