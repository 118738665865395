import { v4 as uuidv4 } from "uuid";

import { Subscription } from "./Subscription";

export class Subject<ValueType> {
  private value: ValueType;
  private subscriptions = new Map<string, Subscription<ValueType>>();

  public observed = false;

  constructor(value: ValueType) {
    this.value = value;
  }

  public subscribe(callback: Subscription<ValueType>["callback"], updateAtStart = true): Subscription<ValueType> {
    const id = uuidv4();

    const subscription = new Subscription(id, callback, this);

    this.subscriptions.set(id, subscription);
    this.observed = true;

    if (updateAtStart) {
      callback({ value: this.value, subscriptionId: id });
    }

    return subscription;
  }

  public unsubscribe(id: string): void {
    this.subscriptions.delete(id);
    this.observed = this.isObserved();
  }

  public getValue(): ValueType {
    return this.value;
  }

  public next(value: ValueType): void {
    const prevValue = this.value;
    this.value = value;

    this.notifySubscribers(this.value, prevValue);
  }

  private isObserved(): boolean {
    return Array.from(this.subscriptions.values()).some((subscription) => subscription.isOpened());
  }

  private notifySubscribers(value: ValueType, prevValue?: ValueType): void {
    Array.from(this.subscriptions.values()).forEach((subscription) => subscription.notify(value, prevValue));
  }
}
