import axios, { AxiosError } from "axios";

import { getToken, refreshToken } from "./token";

const { REACT_APP_API_URL } = process.env;

const injectToken = (headers: Record<string, any>, options: { token?: string }) => {
  const token = options.token || getToken();

  if (token) {
    return {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return headers;
};

export const SPARTA_API = (args?: { token?: string; useLambda?: boolean }) => {
  const { token, useLambda } = args || {};

  const instance = axios.create({
    baseURL: `${REACT_APP_API_URL}${useLambda === false ? "/backend" : ""}`,
    headers: injectToken(
      {
        "Content-Type": "application/json",
      },
      { token }
    ),
  });

  instance.interceptors.response.use(
    (res) => res,
    async (error) => {
      if (
        error instanceof AxiosError &&
        error.code !== "ERR_NETWORK" &&
        [401, 403].includes(error.response?.status || -1) &&
        !error.config?.url?.endsWith("/refresh")
      ) {
        const refreshDone = await refreshToken();

        if (refreshDone) {
          const response = await instance.request({
            ...error.config,
            headers: { ...error.config?.headers, Authorization: `Bearer ${getToken()}` },
          });

          return response;
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};
