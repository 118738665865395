import { Subject } from "./Subject";

type SubscriptionCallback<ValueType> = (args: {
  value: ValueType;
  prevValue?: ValueType;
  subscriptionId: string;
}) => void;

export class Subscription<ValueType> {
  private _id: string;
  private subject: Subject<ValueType>;
  private callback: SubscriptionCallback<ValueType>;
  private closed: boolean;

  constructor(id: string, callback: SubscriptionCallback<ValueType>, subject: Subject<ValueType>) {
    this._id = id;
    this.callback = callback;
    this.subject = subject;
    this.closed = false;
  }

  public get id(): string {
    return this._id;
  }

  public isOpened(): boolean {
    return !this.closed;
  }

  public notify(value: ValueType, prevValue?: ValueType): void {
    if (!this.closed) {
      this.callback({ value, prevValue, subscriptionId: this._id });
    }
  }

  public unsubscribe(): void {
    this.closed = true;
    this.subject.unsubscribe(this._id);
  }
}
